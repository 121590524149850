import * as React from 'react';
import { PageProps, Link } from 'gatsby';

// markup
const IndexPage = (props: PageProps) => {
  console.log(props.data);
  return (
    <>
      <h1>SYNTHESIZE ONLINE</h1>
      <p>
        Get started <Link to="/r">in the lobby</Link>
      </p>
    </>
  )
}

export default IndexPage
